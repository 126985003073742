<template>
  <div>
    <labelList v-if="$route.meta.secondRoute" />
    <router-view />
  </div>
</template>
<script>
import labelList from './labelList.vue'

export default {
  name: 'StudyPlan',
  components: { labelList },
  beforeRouteUpdate(to, from, next) {
    const { courseNo } = from.params
    if (courseNo) to.params.courseNo = courseNo
    next()
  },
}
</script>
<style lang="less" scoped>
.flex {
  display: flex;
}
.switch {
  margin-left: 20px;
  display: flex;
  align-items: center;
}
</style>